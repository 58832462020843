import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

if (!(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')) {
  if (window.location.hostname !== 'www.bobyard.com' && window.location.protocol !== 'https:') {
    window.location.replace(`https://www.bobyard.com${window.location.pathname}`);
  }
}

/*if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development' && window.location.protocol !== 'https:') {
  window.location.replace(`https://${window.location.hostname}${window.location.pathname}`);
} */

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
