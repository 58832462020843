import Skeleton from "react-loading-skeleton";

import 'react-loading-skeleton/dist/skeleton.css'

export default function LandingLogin() {
    window.location.assign('https://app.bobyard.com/login');

    return (
        <div className="login-wrapper">
            <a href="/">
                <div className='login-logo-container'>
                    <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/bobyard+(2).png"} className='login-logo-image' />
                </div>
            </a>

            <div
                className='login-loading-container'
            >
                <Skeleton width={300} height={40} />
                <Skeleton width={300} height={40} />
                <Skeleton width={300} height={40} />
            </div>
        </div>
    );
}